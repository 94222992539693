import React, { useState, useEffect } from 'react'
import * as JsSearch from 'js-search'

import Carousels from '../../components/carousel'
import SearchBar from '../../components/searchBar'
import Layout from '../../components/layout'
import Pager from '../../components/pager'
import Seo from '../../components/seo'

import logo from '../../images/newspaper.png'
import { graphql, Link } from 'gatsby'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons'


const notesAll = ({ data, pageContext }) => {

  const {
    mainNavbar
  } = pageContext

  const {
    allStrapiNotes: { nodes: notes },
    strapiInstitution: institution
  } = data

  const objCarousel = {
    visible: true,
    slide: institution.notesCover?.noteCover
  }

  const page = {
    slug: null,
    ...mainNavbar
  }

  // SearchBar Notes
  const [queryResults, setQueryResults] = useState(notes)
  const [search, setSearch] = useState(null)
  
  const termFrequency = true
  const selectedSanitizer = "Lower Case"
  const removeStopWords = true

  const rebuildIndex = () => {
    const dataToSearch = new JsSearch.Search('title')

    if (removeStopWords) {
      dataToSearch.tokenizer = new JsSearch.StopWordsTokenizer(
        dataToSearch.tokenizer
      )
    }

    dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()

    selectedSanitizer === 'Case Sensitive'
      ? (dataToSearch.sanitizer = new JsSearch.CaseSensitiveSanitizer())
      : (dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer())
    termFrequency === true
      ? (dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex('name'))
      : (dataToSearch.searchIndex = new JsSearch.UnorderedSearchIndex())

    
    dataToSearch.addIndex('title')
    dataToSearch.addIndex(['pageMetadata', 'pageKeywords'])
    dataToSearch.addDocuments(notes) // adds the data to be searched
    setSearch(dataToSearch)
  }

  const handleSearch = (textInput) => {
    textInput.length === 0 ? setQueryResults(notes) : setQueryResults(search.search(textInput))
    textInput.length === 0
  }

  useEffect(() => {
    rebuildIndex()
  }, [])

  // Notas enocntradas en la busqueda
  const searchNotes = queryResults.filter(
    (note) => note
  )  

  return data ? (
    <Layout page={page}>
      <Seo title={`Notas ${mainNavbar?.name || ''}`} />
      <section className="schedule-feed mb-3">
        {objCarousel?.slide ? 
          <div className="d-flex flex-column schedule-header-carousel">
            <Carousels carousel={objCarousel} keyName={'notesFeed-'} />
          </div>
        : null}

        <div className="container">
          <div className="my-5">

            <div className="d-flex align-items-center row mb-4">
              <div className="col-12 col-md-6">
                <div className="border-bottom">
                  <h5 className="text-uppercase ">
                    <FontAwesomeIcon icon={faNewspaper} className="mr-2" />
                    La católica en los{' '} <span className="font-weight-bold text-main pb-1">Medios</span>
                  </h5>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <SearchBar onQuery={handleSearch} placeholder={'Buscá la Nota por nombre, temática o autor'} />
              </div>
            </div>

            <div className="row">
              {searchNotes.length < 1 ? (
                <h4 className="mt-5 col">
                  No se encontraron notas de UCC
                </h4>
              ) : (
                searchNotes?.map((note) => {
                  return (
                    <div
                      className={`mb-4 col-12 col-md-4 'hightlighted'`}
                      key={`news-all-NodesId-${note.strapiId}`}
                    >
                      <div className="h-100 d-flex flex-column rounded shadow-sm news-card overflow-hidden position-relative">
                        <div
                          className="news-image"
                          style={{
                            backgroundImage: `url(${note.thumbnail ? note.thumbnail.url : logo})`
                          }}
                        ></div>
                        <div className="flex-grow-1 p-4 d-flex flex-column news-info">
                          <h6>
                            <small>
                              {moment(note.date)
                                .locale('es')
                                .format('DD-MM-YYYY')}
                            </small>
                          </h6>

                          {/* Etiqueta de la Nota - Utiliza la coleccion Novelties Categories */}
                          {note.novelties_categories?.length > 0 ? (
                            <div className="bg-sec px-2 py-1 text-white mb-2 fit-content">
                              {note.novelties_categories.map((noteCategory, i) => {
                                if ((note.novelties_categories.length -1) > i) {
                                  return noteCategory.name + " - "
                                } else {
                                  return noteCategory.name
                                }
                              })}
                            </div>
                          ) : (
                            ' '
                          )}

                          <h5 className="font-weight-bold">{note.title}</h5>
                          <div className='noticies-markdown markdown-format'>
                              <p>
                                {note.summary}
                              </p>
                          </div>

                          <Link
                            to={`/notas/${note.slug}`}
                            className={`btn btn-outline-info mt-auto fit-content text-uppercase rounded-pill px-4 font-secondary eventuality-button-font-size}`}
                          >
                            LEER NOTA
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                })
              )}
            </div>
            <Pager pageContext={pageContext} />
          </div>
        </div>
      </section>
    </Layout>
  ) : null

}

  

export default notesAll

export const noteListQuery = graphql`
  query($skip: Int, $limit: Int, $date: Date) {
    allStrapiNotes(
      skip: $skip
      limit: $limit
      sort: { fields: date, order: DESC }
      filter: { date: { lte: $date } }
    ) {
      nodes {
        strapiId
        title
        slug
        date
        summary
        moreInformation
        note_authors {
          name
        }
        note_mediums {
          name
        }
        novelties_categories {
          name
        }
        thumbnail {
          url
        }
      }
    }
    strapiInstitution {
      notesCover {
        noteCover {
          content
          imageDescription
          name
          url
          visible
          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
      }
    }
  }
`
